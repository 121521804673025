import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';

import alkaseem from "../assets/img/teams/Alkaseem.png"
import bashir from "../assets/img/teams/Bashir.png"
import blessing from "../assets/img/teams/Blessing.png"
import ibrahimg from "../assets/img/teams/IbrahimG.png"
import ibrahim from "../assets/img/teams/Ibrahim.png"
import maryam from "../assets/img/teams/Maryam.png"
import mrumar from "../assets/img/teams/Mr_Umar.png"
import mubaraq from "../assets/img/teams/Mubaraq.png"
import sadiq from "../assets/img/teams/Sadiq.png"
import sophie from "../assets/img/teams/Sophie.png"
import umaina from "../assets/img/teams/Umaina.png"

const Teams = () => {
    return (
        <div className="teamsBody">
            <Container fluid id="teamsSection">
                <h2 className="text-danger text-center pt-5 mb-5 fw-bold section_head display-5">Meet the Team</h2>
                <div className="teamsDiv">
                    <Row className='text-center'>
                        <Col>
                            <div className='teams-sec'>
                                <div className='tm-1'>
                                    <Image src={blessing} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Blessing Harry</small> <br />
                                        <small className='tm-name'>Lead Business Analyst</small>
                                    </p>
                                </div>
                                <div className='tm-2'>
                                    <Image src={ibrahim} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Ibrahim Abdulkarim</small> <br />
                                        <small className='tm-name'>Manager</small>
                                    </p>
                                </div>
                                <div className='tm-3'>
                                    <Image src={mrumar} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Umar Damcida</small> <br />
                                        <small className='tm-name'>CEO</small>
                                    </p>
                                </div>
                                <div className='tm-4'>
                                    <Image src={sadiq} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Sadiq Ilu</small> <br />
                                        <small className='tm-name'>Lead Developer</small>
                                    </p>
                                </div>
                                <div className='tm-5'>
                                    <Image src={alkaseem} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Alkasim Abubakar</small> <br />
                                        <small className='tm-name'>Developer</small>
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='teams-sec'>
                                <div className='tm-6'>
                                    <Image src={bashir} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Bashir Gimba</small> <br />
                                        <small className='tm-name'>Business Analyst</small>
                                    </p>
                                </div>
                                <div className='tm-7'>
                                    <Image src={umaina} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Umaina Muhammad</small> <br />
                                        <small className='tm-name'>Business Analyst</small>
                                    </p>
                                </div>

                                
                                <div className='tm-8'>
                                    <Image src={ibrahimg} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Ibrahim Gana</small> <br />
                                        <smal className='tm-name'>Business Analyst</smal>
                                    </p>

                                </div>
                                <div className='tm-9'>
                                    <Image src={maryam} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Maryam Muhammed</small> <br />
                                        <small className='tm-name'>Project Manager</small>
                                    </p>
                                </div>


                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className='teams-sec '>
                                
                                <div className='tm-10'>
                                    <Image src={sophie} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Sophie Ayeni</small> <br />
                                        <small className='tm-name'>Developer</small>
                                    </p>
                                </div>
                                <div className='tm-12'>
                                    <Image src={mubaraq} alt='test' className='teammates' />
                                    <p>
                                        <small className='fw-bold tm-name'>Mubaraq Sani</small> <br />
                                        <small className='tm-name' >Developer</small>
                                    </p>
                                </div>



                            </div>


                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default Teams
